import clsx from "clsx";
import Heading from "components/Brand/Heading";
import React from "react";
import MinimalAppCard from "../MinimalAppCard";
import classes from "./styles.module.scss";
import Text from "components/Brand/Text";
const MinimalAppList = ({
  apps,
  vertical = false,
  cardSize = 100,
  title = false,
  className,
  titleClassName,
  ...rest
}) => {
  const combinedClassNames = clsx(
    "flex flex-row justify-start overflow-scroll no-scrollbar gap-2 ",
    vertical &&
      "grid row-span-2 grid-cols-2 gap-2 grid-flow-row place-items-start h-auto flex-1 ",
    className && className
  );
  return (
    <>
      {title && (
        <Text
          as="p"
          variant="normal"
          className={clsx(classes.title, titleClassName)}
          color="primary"
        >
          {title}
        </Text>
      )}
      <div className={combinedClassNames} {...rest}>
        {apps.map((item, index) => {
          return (
            <MinimalAppCard
              key={item.game_id + index}
              item={item}
              index={index}
              cardSize={cardSize}
            />
          );
        })}
      </div>
    </>
  );
};

export default MinimalAppList;
