import { useRouter } from "next/router";
import React from "react";
import { getGamePath, getPlayPath } from "../../../constants/routes.const";

import Image from "next/image";
import { getClientPageUrl } from "utils/index";
import classes from "./styles.module.scss";
import Link from "next/link";
import { LogService } from "service/LogService";
const MinimalAppCard = ({
  item,
  index,
  cardSize = 100,
  imageOptions = {
    sizes: null,
    width: null,
    height: null,
  },
  ...rest
}) => {
  const router = useRouter();
  const clickEventName = router.query?.game
    ? "card_clicked_game_description"
    : "card_clicked_gameplay";
  return (
    // aspect ratio 1:1
    // rounded corners
    <div className={classes.container}>
      <Image
        src={item.imageUrl}
        height={imageOptions?.height || cardSize}
        width={imageOptions?.width || cardSize}
        sizes={imageOptions?.sizes}
        style={{
          minWidth: cardSize,
        }}
        alt={item.imageAlt}
      />
      <Link
        className={classes.text}
        href={getGamePath(item.pathname)}
        onClick={() => {
          LogService.logEvent(clickEventName, {
            game: item.game_id,
            url: getClientPageUrl(),
          });
        }}
        aria-label={item.title}
        prefetch={false}
        style={{
          width: cardSize,
          height: cardSize,
        }}
      >
        {item.title}
      </Link>
    </div>
  );
};

export default MinimalAppCard;
