import Layout from "../components/Layout";
import { AppsData } from "../data/AppsData";
import SEO from "components/seo";
import { useRouter } from "next/router";
import React from "react";
import { organization } from "data/schemaCodes/commonSchema";
import GoogleAdSenseContainer from "adsenseAds/GoogleAdSenseContainer";
import { adSenseAdUnits } from "utils/variants";
import About from "page-components/home/About";
import Features from "page-components/home/Features";
import Benefits from "page-components/home/Benefits";
import FAQ from "page-components/home/FAQ";
import GameGrid from "page-components/home/GameGrid";
import CategoryStrip from "components/CategoryStrip";
import FadeTransition from "components/FadeTransition";
import Content from "components/Layout/Content";
import { CATEGORIES } from "data/categories";
import MinimalAppList from "components/AppsList/MinimalAppList";
import EPButton from "components/UI/EPButton";
import { getCategoryPath } from "constants/routes.const";
import HideOnWWW from "components/HideOnWWW";

const homeAtf = adSenseAdUnits.atfHome1;
export default function Home() {
  const games = [...AppsData];
  const router = useRouter();

  const schemaCodes = [
    {
      type: "application/ld+json",
      code: JSON.stringify(organization),
    },
  ];
  return (
    <>
      <SEO
        url={router.pathname}
        useTitleTemplate={false}
        title="Free Online Games - Play Best PC Games, Browser Games | EpicPlay"
        description="Best place to play free online games on PC, Desktop & Mobile Browser. ✓Trusted by 1000's of Players ✓No Download Needed ✓100% Safe. PLAY NOW!"
        schemaCodes={schemaCodes}
      />
      <CategoryStrip />
      <HideOnWWW>
        <div className="m-auto min-h-[300px]">
          <GoogleAdSenseContainer {...homeAtf} adCheck />
        </div>
      </HideOnWWW>
      <GameGrid games={games} />
      {/* grid 2 on desktop ,mobile 1 */}
      <Content className="px-0 pb-4 pt-0 md:px-8 lg:px-10">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {CATEGORIES.map((category, index) => {
            const Icon = category.icon;
            return (
              <div className="mb-2" key={index}>
                <EPButton
                  variant="link"
                  prefetch={false}
                  href={getCategoryPath(category.pathname)}
                  className="mb-2 inline-flex items-center justify-start gap-2 px-3 md:px-0"
                >
                  <Icon alt={category.name} height="18px" width="18px" />{" "}
                  {category.name} Games
                </EPButton>
                <MinimalAppList
                  key={index}
                  // onClick={() => {
                  //   setActive(category.id);
                  // }}
                  //.filter(game => {
                  //  return game?.categories?.includes(categoryKey)

                  apps={games.filter(game =>
                    game?.categories.includes(category.id)
                  )}
                  cardSize={150}
                  className="px-3 md:px-0"
                />
              </div>
            );
          })}
        </div>
      </Content>
      <About />
      <Features />
      <Benefits />
      <FAQ />

      {/* <OnLoadInterstitialAd /> */}
    </>
  );
}

Home.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};
